import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {StorageService} from "../../service/storage.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [CommonModule, RouterLink, NgOptimizedImage, RouterLinkActive]
})
export class HeaderComponent {

    showUserMenu: boolean = false;

    constructor(
        private readonly authService: MsalService,
        private readonly storageService: StorageService,
    ) {}

    get userName(): string {
        const accounts = this.authService.instance.getAllAccounts();
        return accounts.length > 0 ? accounts[0].name ?? 'Empty' : 'Unauthorized';
    }

    logout() {
        this.storageService.clear();
        this.authService.logout()
    }
}
