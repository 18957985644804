@if (type === 'subnav') {
    <a class="flix-header-nav-subnav__link" [class.flix-btn--disabled]="disabled" (click)="download()"
       (keydown.control.C)="download()">
        @if (icon) {
            <i class="flix-icon flix-btn__icon {{ icon }}" aria-hidden="true"></i>
        }
        @if (title) {
            {{ title }}
        }
    </a>
} @else if (type === 'link') {
    @if (!disabled) {
        <a class="flix-link" (click)="download()" (keydown.control.C)="download()">
            @if (icon) {
                <i class="flix-icon {{ icon }}" aria-hidden="true"></i>
            }
            @if (title) {
                {{ title }}
            }
        </a>
    }
} @else {
    <button class="flix-btn flix-btn--secondary flix-space-1-top" [class.flix-btn--disabled]="disabled" (click)="download()"
            type="button">
        @if (icon) {
            <i class="flix-icon flix-btn__icon {{ icon }}" aria-hidden="true"></i>
        }
        @if (title) {
            {{ title }}
        }
    </button>
}
