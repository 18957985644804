import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-environment-banner',
    template: '<div class="development-ribbon" *ngIf="isDevelopmentEnvironment"><a>DEVELOPMENT</a></div>',
    styleUrls: ['environment-banner.component.scss'],
    imports: [
        NgIf
    ],
    standalone: true
})
export class EnvironmentBannerComponent {
    get isDevelopmentEnvironment(): boolean {
        return !environment.apiBaseUrl().includes("set.flixbus.com")
    }
}
