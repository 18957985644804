import {enableProdMode, importProvidersFrom} from "@angular/core";
import {environment} from "./environments/environment";
import {
    BrowserCacheLocation,
    BrowserUtils,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from "@azure/msal-browser";
import {
    provideRouter,
    Route,
    withDisabledInitialNavigation,
    withEnabledBlockingInitialNavigation
} from "@angular/router";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalService
} from "@azure/msal-angular";
import {bootstrapApplication, BrowserModule} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {provideNoopAnimations} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {canDeactivateGuard} from "./app/shared/guard/can-deactivate.guard";

export function loggerCallback(_logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.auth.clientId,
            authority: environment.msalConfig.auth.authority,
            redirectUri: '/',
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            [environment.apiBaseUrl(), environment.apiConfig.scopes]
        ])
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.apiConfig.scopes]
        },
    };
}

const initialNavigation = !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
    : withDisabledInitialNavigation();

export const Routes: Route[] = [
    {path: '', redirectTo: '/franchise-tax-rule', pathMatch: 'full'},
    {
        path: 'b2c',
        canActivate: [MsalGuard],
        loadComponent: () => import('./app/b2c/container/b2c/b2c.container').then(mod => mod.B2cContainer)
    },
    {
        path: 'franchise-tax-rule',
        canActivate: [MsalGuard],
        loadComponent: () => import('./app/shared/component/country-sidebar/country-sidebar.component').then(mod => mod.CountrySidebarComponent),
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('./app/franchise-tax-rule/container/franchise-tax-rules/franchise-tax-rules.component')
                    .then(mod => mod.FranchiseTaxRulesComponent)
            },
            {
                path: 'create',
                loadComponent: () => import('./app/franchise-tax-rule/container/create-franchise-tax-rule/create-franchise-tax-rule.component')
                    .then(mod => mod.CreateFranchiseTaxRuleComponent),
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: 'edit/:id',
                loadComponent: () => import('./app/franchise-tax-rule/container/create-franchise-tax-rule/create-franchise-tax-rule.component')
                    .then(mod => mod.CreateFranchiseTaxRuleComponent),
                canDeactivate: [canDeactivateGuard]
            },
        ]
    },
    {
        path: 'ticket-type',
        canActivate: [MsalGuard],
        loadComponent: () => import('./app/ticket-type/container/ticket-type/ticket-type.component')
            .then(mod => mod.TicketTypeComponent)
    },
    {
        path: 'subcontract-tax-rule',
        canActivate: [MsalGuard],
        loadComponent: () => import('./app/shared/component/country-sidebar/country-sidebar.component')
            .then(mod => mod.CountrySidebarComponent),
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('./app/subcontract-tax-rule/container/subcontract-tax-rules/subcontract-tax-rules.component')
                    .then(mod => mod.SubcontractTaxRulesComponent)
            },
            {
                path: 'create',
                loadComponent: () => import('./app/subcontract-tax-rule/component/create-subcontract-tax-rule/create-subcontract-tax-rule.component')
                    .then(mod => mod.CreateSubcontractTaxRuleComponent),
                canDeactivate: [canDeactivateGuard]
            },
            {
                path: 'edit/:id',
                loadComponent: () => import('./app/subcontract-tax-rule/component/create-subcontract-tax-rule/create-subcontract-tax-rule.component')
                    .then(mod => mod.CreateSubcontractTaxRuleComponent),
                canDeactivate: [canDeactivateGuard]
            },
        ]
    },
    {
        path: 'ride-type',
        canActivate: [MsalGuard],
        loadComponent: () => import('./app/ride-type/container/ride-type/ride-type.component').then(mod => mod.RideTypeComponent)
    }
];

if (environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule),
        provideRouter(Routes, initialNavigation),
        provideNoopAnimations(),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ]
})
    .catch(err => console.error(err));
