import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CsvService {

    constructor(private httpClient: HttpClient) {
    }

    downloadCsv(url: string, httpParams: HttpParams): void {
        this.httpClient.get<never>(environment.apiBaseUrl() + url, {
            params: httpParams,
            responseType: 'blob' as 'json',
            observe: 'response'
        }).subscribe({
            next: (result) => {
                this.saveAsCsv(result);
            }
        });
    }

    protected saveAsCsv(response: HttpResponse<never>) {
        const body = response.body || '';
        const blob = new Blob([body], {type: 'data=text/csv;charset=utf-8,%EF%BB%BF'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers.get('content-disposition')?.split('filename=')[1].replace(/"/gi, '') || 'unknown.csv';
        link.click();
    }

}
