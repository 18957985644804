import {Component, Input} from '@angular/core';
import {CsvService} from "../../service/csv.service";
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'app-csv-download',
    standalone: true,
    imports: [],
    templateUrl: 'csv-download-component.html',
    styles: ''
})
export class CsvDownloadComponent {

    @Input()
    title: string | undefined = 'Download CSV';
    @Input()
    icon: string | undefined;
    @Input()
    disabled: boolean = false;
    @Input()
    url: string | undefined;
    @Input()
    params: HttpParams | undefined;
    @Input()
    filename: string = 'download.csv';
    @Input()
    type: string = 'button'

    constructor(private csvService: CsvService) {
    }

    download() {
        if (this.url) {
            this.csvService.downloadCsv(this.url, this.params || new HttpParams());
        } else {
            console.warn("No URL for CSV Download available");
        }
    }

}
