<header class="flix-header">
    <div class="flix-header__inner">
        <div class="flix-header-brand flix-header-brand--square">
            <a class="flix-header-brand__link" href="/">
                <img alt="Taxus Logo" class="flix-header-brand__img" height="36" width="36"
                     ngSrc="/assets/taxus_logo.svg"/>
            </a>
        </div>
        <nav aria-label="Main" class="flix-header-nav">
            <ul class="flix-header-nav__list">
                <li class="flix-header-nav__item">
                    <a class="flix-header-nav__link"
                       routerLink="/franchise-tax-rule"
                       routerLinkActive="active"
                       ariaCurrentWhenActive="page">
                        <i aria-hidden="true" class="flix-icon flix-icon-finance-solid"></i>
                        <span class="flix-header-nav__text">Tax Rule (Franchise)</span>
                    </a>
                </li>
                <li class="flix-header-nav__item">
                    <a class="flix-header-nav__link"
                       routerLink="/b2c"
                       routerLinkActive="active"
                       ariaCurrentWhenActive="page"
                       aria-controls="flix-header-nav-b2c">
                        <i aria-hidden="true" class="flix-icon flix-icon-cash-solid"></i>
                        <span class="flix-header-nav__text">B2C Taxes</span>
                    </a>
                </li>
                <li class="flix-header-nav__item">
                    <a ariaCurrentWhenActive="page"
                       class="flix-header-nav__link"
                       routerLink="/ticket-type"
                       routerLinkActive="active">
                        <i aria-hidden="true" class="flix-icon flix-icon-ticket-solid"></i>
                        <span class="flix-header-nav__text">Ticket Types</span>
                    </a>
                </li>
                <li class="flix-header-nav__item">
                    <a class="flix-header-nav__link"
                       routerLink="/subcontract-tax-rule"
                       routerLinkActive="active"
                       ariaCurrentWhenActive="page">
                        <i aria-hidden="true" class="flix-icon flix-icon-finance-solid"></i>
                        <span class="flix-header-nav__text">Tax Rule (Subcontract)</span>
                    </a>
                </li>
                <li class="flix-header-nav__item">
                    <a ariaCurrentWhenActive="page"
                       class="flix-header-nav__link"
                       routerLink="/ride-type"
                       routerLinkActive="active">
                        <i aria-hidden="true" class="flix-icon flix-icon-bus-side"></i>
                        <span class="flix-header-nav__text">Ride Types</span>
                    </a>
                </li>
            </ul>
        </nav>

        <div class="flix-header-widgets">
            <nav aria-label="User" class="flix-header-user-widget">
                <em class="flix-icon flix-icon--size-6 flix-icon-person-business-solid"></em>
                <button (click)="showUserMenu=!showUserMenu"
                        [attr.aria-expanded]="showUserMenu"
                        aria-controls="user-menu-2"
                        aria-haspopup="menu"
                        aria-label="Open user menu"
                        class="flix-header-user-widget__toggle"
                        type="button">
                    <span class="flix-header-user-widget__name">{{ userName }}</span>
                </button>
                <ul (mouseenter)="showUserMenu=true" (mouseleave)="showUserMenu=false"
                    class="flix-header-nav-subnav flix-header-nav-subnav--left"
                    id="user-menu-2">
                    <li class="flix-header-nav-subnav__item">
                        <a (click)="logout()" (keydown)="logout()" class="flix-header-nav-subnav__link">
                            <span class="flix-header-nav__text">Logout</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>