import {Component} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {CsvDownloadComponent} from "../data/csv-download.component";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterLink, NgOptimizedImage, RouterLinkActive, CsvDownloadComponent]
})
export class HeaderComponent {

    showB2cSubmenu = false;

    constructor(
        private authService: MsalService,
    ) {}

    get isAuthenticated(): boolean {
        return this.authService.instance.getAllAccounts().length > 0;
    }

    get userName(): string {
        const accounts = this.authService.instance.getAllAccounts();
        return accounts.length > 0 ? accounts[0].name || 'Empty' : 'Unauthorized';
    }
}
